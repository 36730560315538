$(function () {
    if ($.cookie('__cookie_kuningan_de') == null) {
        setTimeout(function () {
            $('#cookies-bar').fadeIn();
        }, 2000);
    }

    $('#cookie-settings').on('click', function () {
        if ($.cookie('__cookie_kuningan_de_essential')) {
            $('#essential-cookies').attr('checked', 'checked');
        }

        if ($.cookie('__cookie_kuningan_de_marketing')) {
            $('#marketing-cookies').attr('checked', 'checked');
        }

        if ($.cookie('__cookie_kuningan_de_functional')) {
            $('#functional-cookies').attr('checked', 'checked');
        }

        if ($.cookie('__cookie_kuningan_de_analytics')) {
            $('#analytics-cookies').attr('checked', 'checked');
        }
    });

    $('#btn-save-cookies').on('click', function () {
        $.cookie('__cookie_kuningan_de', true, { expires: 30 });
        $.cookie('__cookie_kuningan_de_essential', true, { expires: 30 });

        if ($('#marketing-cookies').is(':checked')) {
            $.cookie('__cookie_kuningan_de_marketing', true, { expires: 30 });
        }

        if ($('#functional-cookies').is(':checked')) {
            $.cookie('__cookie_kuningan_de_functional', true, { expires: 30 });
        }

        if ($('#analytics-cookies').is(':checked')) {
            $.cookie('__cookie_kuningan_de_analytics', true, { expires: 30 });
        }
        
        $('#cookies-bar').fadeOut();
    });

    $('#cookie-accept').on('click', function () {
        $.cookie('__cookie_kuningan_de', true, { expires: 30 });
        $.cookie('__cookie_kuningan_de_essential', true, { expires: 30 });
        $.cookie('__cookie_kuningan_de_marketing', true, { expires: 30 });
        $.cookie('__cookie_kuningan_de_functional', true, { expires: 30 });
        $.cookie('__cookie_kuningan_de_analytics', true, { expires: 30 });

        $('#cookies-bar').fadeOut();
    });

    $('#cookie-close').on('click', function () {
        $('#cookies-bar').fadeOut();
    });
});
