$(function () {
    Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get: function () {
            return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        }
    });


    $('body').on('click touchstart', function () {
            const videoElement = document.getElementById('video-mobile');
            if (!videoElement) { return; }
            if (videoElement.playing) {
                // video is already playing so do nothing
            }
            else {
                // video is not playing
                // so play video now
                videoElement.play();
            }
    });


    $("#video").on('onmouseover', function () {
        if ($("#video")[0].paused) {
            $('#video')[0].play();
        }
    });

    $("#video-mobile").on('onmouseover', function () {
        if ($("#video-mobile")[0].paused) {
            $('#video')[0].play();
        }
    });
});
