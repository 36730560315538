$(function () {
   if (window.scrollY > 122) {
        $('#btnToTop').fadeIn();
        $('#header-fixed').fadeIn('slow');
    }

    window.addEventListener('scroll', function () {
        if (window.scrollY > 122) {
            $('#btnToTop').fadeIn();
            $('#header-fixed').fadeIn('slow');
        } else {
            $('#btnToTop').fadeOut();
            $('#header-fixed').fadeOut();
        }
    });

    $('a[data-target="#about"]').on('click', function () {
        $('html, body').animate({
            scrollTop: $("#about").offset().top - 60
        });
    });

    $('a[data-target="#solutions"]').on('click', function () {
        $('html, body').animate({
            scrollTop: $("#solutions").offset().top - 60
        });
    });

    $('a[data-target="#industries"]').on('click', function () {
        $('html, body').animate({
            scrollTop: $("#industries").offset().top - 60
        });
    });

    $('a[data-target="#contacts"]').on('click', function () {
        $('html, body').animate({
            scrollTop: $("#contacts").offset().top - 60
        });
    });

    $('#btnToTop').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        });
    }); 
});